/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useLayoutEffect } from "react";
import Slider from "react-slick";
import { employeeSettings } from "../../assets/sliderSetting";
import { Row, Col, Spinner } from "reactstrap";
import { useSelector } from "react-redux";
// import { Home } from "../../components/Dashboard/Home/Home";
import Ocard from "../../components/Dashboard/orderCard/ocard";
// import // getOrders,
// , getSingleOrder
// "../../store/actions/orderAction";
// import { CLEAR_ID, CLEAR_ORDER_ID } from "../../store/types";
// import { GOTO_RECALLPAGE } from "../../store/types";
// import StartCookModal from "../../components/Dashboard/Home/CookModal/startCook";
import { useLocation } from "react-router-dom";
import StepsModal from "../../components/Modals/stepsModal";
import SelectStation from "../../components/Modals/selectStation";
// import MultiOrders from "../../components/Dashboard/orderCard/multiorders";
import Dishes from "../../components/Dashboard/orderCard/dishes";

export default function HomeView() {
  // const dispatch = useDispatch();
  const location = useLocation();
  const loading = useSelector((state) => state?.orders?.loading);
  const SingleLoading = useSelector((state) => state?.orders?.singleLoading);
  const singleDataStore = useSelector((state) => state?.orders?.singleOrder);
  const dataStore = useSelector((state) => state?.orders?.orders);
  const stations = useSelector((state) => state?.stations?.stations);

  var selected_menu = location?.state?.menu;

  const [ordersMenu, setOrderMenu] = useState([]);
  const [stationSlectModal, setStationSelectModal] = useState(false);
  const [orderObj, setOrderObj] = useState({});
  const [updateOrder, setUpdateOrder] = useState();
  const [listenDataChange, setListenDataChange] = useState(false);
  const [drinksItems, setDrinksItems] = useState([]);

  const activeStation = stations?.find(
    (item) => item?.title?.toLowerCase() === selected_menu?.toLowerCase()
  );

  useEffect(() => {
    loadData();
  }, [
    selected_menu,
    location.pathname,
    dataStore,
    singleDataStore,
    listenDataChange,
  ]);
  useLayoutEffect(() => {
    if (selected_menu === activeStation?.title) {
      filterdDrinks();
    }
  }, [selected_menu, dataStore]);
  const filterdDrinks = () => {
    const filteredData = dataStore
      .filter((item) => {
        const drinks = item.salesEntries?.filter(
          (entry) =>
            entry.station?.toLowerCase() === selected_menu?.toLowerCase() ||
            entry.station === selected_menu
        );
        return drinks.length > 0;
      })
      .map((item) => {
        const drinks = item.salesEntries
          ?.filter(
            (entry) =>
              (entry?.station?.toLowerCase() === selected_menu?.toLowerCase() ||
                entry?.station === selected_menu) &&
              !entry.serve_status
          )
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        const latestItem = drinks[0]?.createdAt;
        return { ...item, latestItem };
      })
      .sort((a, b) => {
        const dateA = new Date(a.latestItem);
        const dateB = new Date(b.latestItem);
        if (dateA < dateB) {
          return 1; 
        }
        if (dateA > dateB) {
          return 1; 
        }
        return 0; 
      });
  
    setDrinksItems(filteredData);
  };
  function loadData() {
    let tempData =
      dataStore &&
      [...dataStore]
        ?.filter((_item) => {
          if (activeStation?.autoServe) {
            return true;
          }
          if (_item?.station) {
            return true;
          }
          return false;
        })
        ?.sort(function(a, b) {
          // Check if both objects have a station property
          if (a.station && b.station) {
            // Sort by latestItem if both have station
            return new Date(a.latestItem) - new Date(b.latestItem);
          } else if (a.station) {
            // Sort by station first if only a has station
            return -1;
          } else if (b.station) {
            // Sort by station first if only b has station
            return 1;
          } else {
            // Sort by latestItem if neither has station
            return new Date(a.latestItem) - new Date(b.latestItem);
          }
        });
    if (singleDataStore?._id !== undefined) {
      let orderIndex = tempData?.findIndex((item) => {
        return item?._id === updateOrder?._id;
      });
      if (orderIndex !== -1) {
        if (
          updateOrder?.bar &&
          tempData[orderIndex]?._id === singleDataStore?._id
        ) {
          //replace entire order to accomodate bar status
          tempData[orderIndex] = singleDataStore;
        } else {
          let saleEntryIndex = tempData[orderIndex]?.salesEntries?.findIndex(
            (saleEntry) => {
              return saleEntry?._id === updateOrder?.dish_id;
            }
          );
          let newDishIndex = singleDataStore?.salesEntries?.findIndex((x) => {
            return x._id === updateOrder?.dish_id;
          });

          if (newDishIndex !== -1 && saleEntryIndex !== -1) {
            //for updation
            tempData[orderIndex].salesEntries[saleEntryIndex] =
              singleDataStore?.salesEntries[newDishIndex];
            // }
          }
        }
      }
    }
    let saleEntries = [];
    let se = tempData?.map((btx) => btx?.salesEntries);
    let flatArray = se?.flat();

    if (location?.state?.order === "cooked") {
      saleEntries = flatArray?.filter((itx) => {
        return (
          itx?.status === "cooked" ||
          itx?.payment_status === "half_paid" ||
          itx?.payment_status === "paid"
        );
      });
      setOrderMenu(
        saleEntries?.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      );
    } else {
      let not_cooked = flatArray?.filter((itx) => {
        return itx?.status === "pending" || itx?.status === "cooking"; //remove cooked item from view, will show cooked in RECALL view
      });

      saleEntries = not_cooked?.filter((itx) => {
        if (selected_menu !== "all")
          return itx?.station?.toLowerCase() === selected_menu?.toLowerCase();
        else return itx;
      });
      const filteredData = saleEntries?.sort((a, b) => {
        if (a?.priority === "urgent" && b?.priority !== "urgent") {
          return -1;
        } else if (a?.priority !== "urgent" && b?.priority === "urgent") {
          return 1;
        }
      });

      setOrderMenu(filteredData);
    }
  }

  if (ordersMenu?.length === 0 && loading) {
    return (
      <div className="position-relative min-vh-100 w-100">
        <Spinner className=" position-absolute top-50 start-50" />
      </div>
    );
  } else {
    return (
      <>
        {activeStation?.displayMode === "Divider View" ? (
          <Dishes
            data={drinksItems && [...drinksItems]}
            setUpdateOrder={setUpdateOrder}
            updateOrder={updateOrder}
            SingleLoading={SingleLoading}
            setListenDataChange={setListenDataChange}
            listenDataChange={listenDataChange}
          />
        ) : (
          <Row className="mb-5">
            <Col md="12" lg="12" sm="12" xs="12">
              {ordersMenu?.length === 0 && !loading ? (
                <div className=" mt-5  text-muted d-flex align-items-center justify-content-center">
                  <p>
                    Sorry this station does not have a relevant order or dish.
                  </p>
                </div>
              ) : (
                <Slider {...employeeSettings} className="mx-5">
                  {ordersMenu?.map((si) => {
                    if (si !== undefined)
                      return (
                        <div>
                          <Ocard
                            dish={si}
                            data={[...dataStore]}
                            activeStation={activeStation}
                            setStationSelectModal={setStationSelectModal}
                            setOrderObj={setOrderObj}
                            setUpdateOrder={(obj) => {
                              setUpdateOrder(obj);
                            }}
                            SingleLoading={
                              SingleLoading && updateOrder?.dish_id === si?._id
                            }
                            setListenDataChange={setListenDataChange}
                            listenDataChange={listenDataChange}
                          />
                        </div>
                      );
                  })}
                </Slider>
              )}
            </Col>
          </Row>
        )}
        <StepsModal />
        <SelectStation
          stationSlectModal={stationSlectModal}
          setStationSelectModal={setStationSelectModal}
          orderObj={orderObj}
          setListenDataChange={setListenDataChange}
        />
        {/* <StartCookModal
        startCooking={cookingModalOpen}
        cardId={cookingModalObj?.cardId}
        saleEntry={cookingModalObj.saleEntry}
        setStartCooking={setCookingModalOpen}
      /> */}
      </>
    );
  }
}
