import React from "react";
import { Button, Col, Row } from "reactstrap";
import ModalInterface from "../ModalInterface";

const ServiceNotification = ({ notifyModal, setNotifyModal, notifyMsg }) => {
  return (
    <>
      <ModalInterface
        display={notifyModal}
        ceneter={true}
        bsSize={"md"}
        content={
          // loading ? <div className="d-flex justify-content-center align-items-center">
          //     <Spinner animation="border" />
          // </div> :
          <>
            <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <div className="msg-wrapper p-3">
                  <p>
                    {`TISCH ${notifyMsg?.tableNo}`}
                    <br />
                    {`Message: "${notifyMsg?.message}"`}
                  </p>
                  {/* <p>{`"${notifyMsg?.message}" TISCH ${
                    notifyMsg?.tableNo
                  } with Session ID ${notifyMsg?.sessionId?.substr(
                    notifyMsg?.sessionId.length - 4
                  )} kindly check that out`}</p> */}
                  <div className="mt-4 d-flex justify-content-center">
                    <Button
                      outline
                      color="danger"
                      onClick={() => {
                        setNotifyModal(false);
                      }}
                    >
                      Dismiss
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        }
        closeEvent={() => {
          setNotifyModal(false);
        }}
      />
    </>
  );
};

export default ServiceNotification;
