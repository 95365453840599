/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
export function convertToHtml(tablenumber,data) {
    let html = `
      <div>
        <div style="text-align: center;">TABLE  ${tablenumber}</div>


        <div style="text-align: center;">  ${data.itemName}  ${data?.quantity}</div>

    `;
  
    if ((data.station === "Bar" || data.station === "bar" || data.station === "Bar" || data?.station === "Cocktails Station") && data.status !== "cooked") {

        if (data?.subLineItems && data.subLineItems.length > 0) {
         
            data.subLineItems.forEach((sub) => {
              html += `
                <div style="width: 100%; text-align: center;">${sub?.itemName} ${sub?.quantity}</div>
              `;
            });
        
         
          }
 
    }
  
    html += `
        </div>
        <div style="width: 100%; text-align: center;"></div>
        <div style="margin-top: 30px;"></div>
        <hr/>
      </div>
    `;
  
    return html;
  }