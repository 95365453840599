/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react"; // , useState // useEffect,
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { payWaiter } from "../../../../store/actions/tableSessionsAction";
// import { getOrders } from "../../../store/actions/orderAction";
import { Spinner } from "reactstrap";
import {
  removeSetSelection,
  setTableToMerge,
} from "../kellnerActive/SelectTableCard";
import axios from "axios";
import { IoMdNotifications, IoMdNotificationsOff } from "react-icons/io";
// import { getOrders } from "../../../../store/actions/orderAction";

const KellnerReadyCard = ({
  cookOrder,
  setSelectedSession,
  setWarn,
  setAlertMsg,
  activeTab,
}) => {
  const dispatch = useDispatch();
  const { tableNo } = useSelector((state) => state?.modal);
  const orders = useSelector((state) => state.orders.orders);
  const parentOrders = useSelector((state) => state.orders.parentOrders);
  const payWaiterLoader = useSelector(
    (state) => state.tableSession.payWaiterLoader
  );
  const [payWaiterLoading, setPayWaiterLoading] = useState(false);
  let order = cookOrder?.isParent
    ? parentOrders?.find((item) => item.sessionId === cookOrder?._id)
    : orders?.find((item) => item.sessionId === cookOrder?._id);

  //for GET Orders >>>>>>>>>
  // useEffect(() => {
  //   dispatch(getOrders());
  // }, []);
  useEffect(() => {
    if (!payWaiterLoader) {
      setPayWaiterLoading(payWaiterLoader);
    }
  }, [payWaiterLoader]);

  const notifyKitchen = (finalBill) => {
    let msgDetails = `${new Date()} | Pay to Waiter | Table Name: ${cookOrder?.table_id
      } | Order Total: €${finalBill?.toFixed(2)}`; //Tip Amount: €${(total - subAmount).toFixed(2)
    // const msg = {
    //   problemID: uuidv4(),
    //   tableNo: cookOrder?.table_id,
    //   sessionID: cookOrder?._id,
    //   message: msgDetails.trim(),
    //   payWaiter: true,
    // };
    // socket.emit("Problem_", msg);
    msgDetails = msgDetails + `| Table No: ${cookOrder?.table_id}`;
    return msgDetails;
  };

  let tableType = useMemo(() => cookOrder?.table_type, [cookOrder])

  const handleWaiterPayment = () => {
    if (order?.payment_status === "pending") {
      let finalBill = order?.salesEntries?.reduce(function(acc, obj) {
        return acc + Number(obj.itemPrice);
      }, 0);
      const waiterPayload = {
        businessLocationId: String(cookOrder?.businessLocationId),
        thirdPartyReference: uuidv4(),
        orderType: "served",
        sessionID: cookOrder?._id,
        tableNumber: Number(cookOrder?.table_id),
        orderNote: notifyKitchen(finalBill),
        totalAmount: finalBill,
      };
      setPayWaiterLoading(true);
      dispatch(payWaiter(waiterPayload));
    } else {
      setAlertMsg("Oops..! This Order has been paid.");
      setWarn(true);
    }
  };

  const [isPaymentCanceled, setIsPaymentCanceled] = useState(false);

  const getCanceledPaymentInfo = async () => {
    try {
      console.log(cookOrder?._id);
      const response = await axios.get(
        process.env.REACT_APP_BackendURL +
        `/payment-info/session/${cookOrder?._id}`,
        {}
      );
      if (response.data === "Canceled") return true;
    } catch (error) {
      console.error("Error fetching payment info:", error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const canceledPaymentInfo = await getCanceledPaymentInfo();
        setIsPaymentCanceled(canceledPaymentInfo);
      } catch (error) {
        console.error("Error fetching payment info:", error.message);
      }
    };

    fetchData();
  }, [cookOrder?._id]);

  return (
    <div
      className={
        cookOrder?.order_status === "cooking" && activeTab !== "ready"
          ? "k-card-cooking mt-2"
          : "k-card mt-2"
      }
    >
      <div
        className={
          tableNo?.firstTableName?.id === cookOrder?._id
            ? "item-overlay"
            : "abc"
        }
        onClick={() => {
          removeSetSelection(dispatch);
        }}
      ></div>
      <div className="p-3">
        <div className="" onClick={setSelectedSession}>
          <b>
            {cookOrder?.pagerNumber
              ? cookOrder.pagerNumber
              : cookOrder?.table_name}
          </b>
        </div>
        <div
          className="d-flex action-session"
          onClick={() => {
            setTableToMerge(dispatch, cookOrder);
          }}
        >
          <img src="/img/icons/selection.png" height={20} />
        </div>
        <div className="">
          TABLE <b>{cookOrder?.table_id}</b>
        </div>
        <div style={{ textAlign: "center" }}>
          {cookOrder.notificationStatus !== undefined && tableType == 'Pick-up' ? (
            <div style={{
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              display: 'flex',
              paddingTop: '1rem'
            }}>
              {cookOrder.notificationStatus ? (
                <IoMdNotifications />
              ) : (
                <IoMdNotificationsOff color="red" />
              )
              }
            </div>
          ) : (
            <div style={{ width: "20px", height: "33px" }}></div>
          )
          }
        </div>
        <div style={{ textAlign: "center" }}>
          {isPaymentCanceled &&
            <img
              src="/img/alert.gif"
              alt="GIF"
              style={{ width: "15px", height: "15px" }}
            />
          }
        </div>
      </div>
      <div className="k-info pt-2 pb-3">
        <div className="served d-flex justify-content-center">
          {cookOrder?.station &&
            cookOrder.order_status === "cooking" &&
            activeTab !== "ready" && (
              <div className="w-100 py-1 d-flex justify-content-center bg-green">
                <b>{cookOrder?.station}</b>
              </div>
            )}
          <div className=" w-100 py-1 d-flex justify-content-center bg-yellow ">
            SERVED
          </div>
        </div>
      </div>
      <div className="btn-k-pay d-flex justify-content-center pb-3">
        <button
          disabled={payWaiterLoading}
          onClick={handleWaiterPayment}
          className="k-pay bg-darkBlue"
        >
          {payWaiterLoading ? <Spinner /> : "KELLNER PAY"}
        </button>
      </div>
    </div>
  );
};

export default KellnerReadyCard;
