/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { socket } from "../../../services/services.sockets";
import {
  cookingProcessUpdate,
  toggleBarLoader,
} from "../../../store/actions/orderAction";
import // changeBumpStatus,
// selectStation,
"../../../store/actions/tableSessionsAction";
import { CLEAR_ID, CLEAR_ORDER_ID, GET_ID, ORDER_DISH_COOK } from "../../../store/types";
// import { convertToXml } from "../../PrintAssets/printxml";
import OcardHeader from "./ocardHeader";
import OrderFooter from "./ofooter";
import { useRef } from "react";
import { convertToXml } from "../../PrintAssets/printxml";
import axios from "axios";
import { convertToHtml } from "../../PrintAssets/printHtml";

const MultiOrders = ({
  order,
  setUpdateOrder,
  SingleLoading,
  setListenDataChange,
  selected_menu,
}) => {
  const dispatch = useDispatch();
  const [cookingStatus, SetCookingStatus] = useState("");
  const idStore = useSelector((store) => store?.info?.id);
  const [tablenumber,setTablenumber]=useState();
  const dishStationChangeLoader = useSelector(
    (state) => state?.orders?.dishStationChangeLoader
  );
  const dishPeriorityLoading = useSelector(
    (state) => state?.orders?.dishPeriorityLoading
  );
  const removeItemLoading = useSelector(
    (state) => state?.orders?.removeItemLoading
  );
  const orderPriortyChangeLoader = useSelector(
    (state) => state?.orders?.orderPriortyChangeLoader
  );
  const cookingUpdateLoader = useSelector(
    (state) => state?.orders?.cookingUpdateLoader
  );
  const sessions = useSelector((state) => state?.tableSession?.sessions);
  const stations = useSelector((state) => state?.stations?.stations);
  const selectedDish = useSelector((state) => state?.info.id.Id);
  const selectedOrder = useSelector((state) => state?.info.oId);
  const [dataLoading, setDataLoading] = useState(false);
  useEffect(() => {
    if (!dishPeriorityLoading) {
      setDataLoading(dishPeriorityLoading);
    } else if (order._id === selectedDish) {
      setDataLoading(dishPeriorityLoading);
    }
  }, [dishPeriorityLoading, order._id, selectedDish]);
  useEffect(() => {
    if (!orderPriortyChangeLoader) {
      setDataLoading(orderPriortyChangeLoader);
    } else if (order._id === selectedOrder?.Id) {
      setDataLoading(orderPriortyChangeLoader);
    }
  }, [orderPriortyChangeLoader, order._id, selectedDish?._id]);
  useEffect(() => {
    if (!dishStationChangeLoader) {
      setDataLoading(dishStationChangeLoader);
    } else if (order._id === selectedDish) {
      setDataLoading(dishStationChangeLoader);
    }
  }, [dishStationChangeLoader, order._id, selectedDish]);
  useEffect(() => {
    if (!removeItemLoading) {
      setDataLoading(removeItemLoading);
    } else if (order._id === selectedDish) {
      setDataLoading(removeItemLoading);
    }
  }, [removeItemLoading, order._id, selectedDish]);
  useEffect(() => {
    if (!cookingUpdateLoader) {
      setDataLoading(cookingUpdateLoader);
    }
  }, [cookingUpdateLoader]);
  let componentRef = useRef();
  let printref = useRef();
  const activeStation = stations?.find(
    (item) => item?.title.toLowerCase() === selected_menu.toLowerCase()
  );

  //===============ITem Cooking Process===============
  const dishProcess = (item) => {

    if (item?.status === "cooking") {
      if (activeStation.printType === "web") {
       if(item?.station === "bar")    handlePrint();
        cookingOrder(item);
      } else if (activeStation.printType === "mobile") {
        handlePrintXML(order);
        cookingOrder(item);
      }
    }
  };
  //=============================== COOKING ORDER ======================================
  const cookingOrder = (item) => {
    setUpdateOrder({
      _id: order?._id,
      bar: true,
      // dish_id: item?._id,
      businessLocationId: order?.businessLocationId,
    });
    dispatch(toggleBarLoader(true));
    setDataLoading(true);
    setListenDataChange(true);
    dispatch(
      cookingProcessUpdate({
        order_id: order?._id,
        sales_entry_id: item?._id,
        dish_status:
          item?.status === "pending"
            ? "cooking"
            : item?.status === "cooking"
            ? "cooked"
            : "pending",
        bump: true,
        bumpStation: "BAR",
        sessionId: order?.sessionId,
        sessions,
      })
    ).then(() => {
      setDataLoading(false);
      setListenDataChange(false);
      socket.emit("processing", {});
    });
  };
  const cookingProcessUpdate3 = async ({
    order_id,
    sales_entry_id,
    dish_status,
    bump,
    bumpStation,
    sessionId,
    sessions,
  }) => {
    try {
      const updatedOrder = await axios.put(`${process.env.REACT_APP_BackendURL}/order/order-processing-v3`, {
        order_id,
        sales_entry_id,
        dish_status,
        bump,
        bumpStation,
        sessionId,
        sessions,
      })
      console.log("reutnred updated order", updatedOrder)

    } catch (error) {
      console.log("ERROR UPDATING ORDER ", error)

    }
  }
  //===============ITem Cooking Process===============
  const itemProcess = (item) => {
    console.log("item to print",item)
    // console.log("__order",item);
    // if (item?.status === "cooking") {
    if (activeStation.printType === "web") {
      if (item?.status === "cooking" && item?.station === "bar")  handlePrint2(tablenumber,item);
      cookingItem(item);
    } else if (activeStation.printType === "mobile") {
     handlePrintXML(item);
      cookingItem(item);
    }
    // }
  };
  //=============================== COOKING ORDER ======================================
  const cookingItem = (item) => {
    setDataLoading(true);
    setListenDataChange(true);
    setTablenumber(order?.tableNumber);
    // SetOrderSelectForCook(order, item);
    cookingProcessUpdate3({
      order_id: order?._id,
      sales_entry_id: item?._id,
      dish_status: item?.status === "pending" ? "cooking" : "cooked",
      bump: true,
      bumpStation: "BAR",
      sessionId: order?.sessionId,
      sessions,
    })
      .then(() => {
        setDataLoading(false);
        setListenDataChange(false);
        socket.emit("processing", {});
      });
  };
  const SetOrderSelectForCook = (order, item) => {
    dispatch({
      type: ORDER_DISH_COOK,
      payload: {
        orderid: order,
        saleEntry: item,
      },
    });
  };
  //=================ITem Select for PRIORITY And DELTEION===============
  // const itemSelect = (id, item) => {
  //   setUpdateOrder({
  //     _id: order?._id,
  //     dish_id: item?._id,
  //     businessLocationId: order?.businessLocationId,
  //   });
  //   dispatch({ type: CLEAR_ORDER_ID });
  // };
  const StationItemChange = (id, item) => {
    dispatch({
      type: GET_ID,
      payload: { Id: id, stationName: item },
    });
    dispatch({ type: CLEAR_ORDER_ID });
  };
  //=================================
  useEffect(() => {
    const allCooked = order?.salesEntries?.every((x) => {
      return x?.status === "cooked";
    });
    const paid = order?.salesEntries?.every((x) => {
      return x?.payment_status === "paid" || x?.payment_status === "half_paid";
    });
    const cookingOne = order?.salesEntries?.some((x) => {
      return x?.status === "cooking";
    });
    const pendingsome = order?.salesEntries?.some((x) => {
      return x?.status === "pending";
    });
    const allPending = order?.salesEntries?.every((x) => {
      return x?.status === "pending";
    });
    if (allCooked === true) {
      SetCookingStatus("bgplaygreen");
    } else if (cookingOne) {
      SetCookingStatus("bgorder-yellow");
    } else if (allPending) {
      SetCookingStatus("bgOrder-light-Gray");
    } else if (paid) {
      SetCookingStatus("bgplayWhite");
    } else if (pendingsome) {
      SetCookingStatus("bgOrder-light-Gray");
    }
  }, [order]);
  const handlePrint2 = (tablenumber,contentToPrint) => {

    const contentString2 = convertToHtml(tablenumber,contentToPrint);
    var pri = printref.current.contentWindow;
    pri.document.open();
    pri.document.write(contentString2);
    pri.document.close();
    pri.focus();
    pri.print();
  };
  const handlePrint = () => {
    var content = componentRef.current;
   // console.log("__content",componentRef)
    var pri = printref.current.contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };
  const handlePrintXML = (order) => {
    const dataConverted = convertToXml(order);
    printWithTMAssistant(
      "tmprintassistant://",
      "tmprintassistant.epson.com/",
      "print?",
      "success",
      "ver",
      "data-type",
      "data",
      dataConverted,
      "1",
      "eposprintxml",
      "yes",
      "reselect"
    );
  };
  function printWithTMAssistant(
    appscheme,
    host,
    action,
    query_success,
    query_ver,
    query_datatype,
    query_data,
    xmlData,
    ver,
    datatype,
    reselect,
    query_reselect
  ) {
    // create the URL string
    var urlData =
      appscheme +
      host +
      action +
      query_success +
      "=" +
      encodeURIComponent("") +
      "&" +
      query_ver +
      "=" +
      ver +
      "&" +
      query_datatype +
      "=" +
      datatype +
      "&" +
      query_reselect +
      "=" +
      reselect +
      "&" +
      query_data +
      "=" +
      encodeURIComponent(xmlData);
    window.location.replace(urlData);
  }

 const colorReturn = (di) => {
    return di?.status === "cooked" ||
      di?.payment_status === "half-paid" ||
      di?.payment_status === "paid"
      ? `item-station bg-green`
      : di?.status === "cooking"
        ? `item-station bg-yellow`
        : `item-station bg-gray`;
  };

  return (
    <>
      <div
        className={
          order?.tableNumber === 2000 ? "o-card-head2000" : "o-card-head"
        }
      >
        <div className="content-card">
          <OcardHeader
            // dish={di}
            order={order}
          //   tableNo={order?.tableNumber}
          //   setSingleOrder={setSingleOrder}
          />
          <div className="takeaway-sec d-flex justify-content-between">
            <span>{order?.order_type?.toUpperCase()}</span>
            <span>
              {order?.sessionName !== null ? order?.sessionName : "12:45"}
            </span>
          </div>
          <div className="o-card-body ">
            {SingleLoading === true || dataLoading ? (
              <div className="d-flex justify-content-center align-items-center mt-5 card-item ">
                <Spinner />
              </div>
            ) : (
              <>
                {order?.salesEntries.map((di) => {
                  if (
                    di?.station === selected_menu &&
                    (di?.status === "cooking" || di?.status === "pending")
                  )
                    return (
                      <>
                        <div
                          className={
                            di?.priority === "urgent"
                              ? "item-sec bghotpink d-flex align-items-center justify-content-between pl-2"
                              : "item-sec d-flex align-items-center justify-content-between pl-2 card-item"
                          }
                        >
                          {idStore?.stationName?._id === di?._id ?
                            (
                              <span
                                className="item-name-select"
                                onClick={() => {
                                  dispatch({ type: CLEAR_ID });
                                }}
                              >
                                {""}
                                {di?.itemName?.toUpperCase()}
                              </span>
                            ) : (
                              <span
                                className={"item-name-ocard f-14 px-1"}
                                onClick={() => {
                                  // dishProcess(di);
                                  StationItemChange(order?._id, di);
                                }}
                              >
                                {di?.itemName !== null
                                  ? `${di?.itemName?.toUpperCase()}`
                                  : "KAMUT BASE"}
                              </span>
                            )}
                          {/* TODO */}
                          <div
                            onClick={() => {
                              itemProcess(di);
                            }}
                            className={colorReturn(di)}
                          >
                            <span>{di?.station?.toUpperCase()}</span>
                          </div>
                        </div>
                        <div
                          className={
                            di?.subLineItems?.length > 0
                              ? "order-body pb-2"
                              : ""
                          }
                        >
                          {/* <div className="ingredients-sec mt-1 ">
                            <div className="bill-img">
                              <img
                                src="/img/icons/BILL_GRAY.png"
                                height={20}
                                alt="bill"
                              />
                            </div>
                            {di?.ingredients?.map((ing, key) => {
                              return (
                                <div className="ing-name px-2" key={key}>
                                  <span className=" d-block">
                                    <span>{parseInt(ing?.quantity)} -</span>{" "}
                                    {ing?.name}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                          <div className="invisible">ABV</div>
 */}
                          <div className="dishName d-block px-2">
                            {di?.subLineItems?.map((sl, index) => {
                              return (
                                <div key={index}>
                                  {(sl.type === "standard" ||
                                    sl.type === "Standard" ||
                                    sl.type === "normal") &&
                                    sl.isSingleOnly === true ? (
                                    <>
                                      <div className="d-flex ">
                                        <img
                                          src="/img/icons/MIDARROWRIGHT_BLACK.png"
                                          className="pt-1"
                                          height={20}
                                          alt="item-logo"
                                        />
                                        <span className="f-14 px-1">
                                          {sl?.itemName}
                                        </span>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              );
                            })}
                          </div>

                          <div className="two-card-sec ">
                            {di?.redItems?.length > 0 ? (
                              <div className="block-card bg-pink py-3">
                                <span>
                                  {" "}
                                  <img
                                    src="/img/icons/MINUS_BLACK.png"
                                    alt="minus"
                                    height={12}
                                  />
                                </span>
                                {di?.redItems?.map((item, index) => {
                                  return (
                                    <div
                                      className="card-porduct pl-2"
                                      key={index}
                                    >
                                      <span className="d-block ">
                                        {item?.itemName}{" "}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              ""
                            )}
                            {di?.greenItems?.length > 0 ? (
                              <div className="block-card bg-lightGreen py-3">
                                <span>
                                  {" "}
                                  <img
                                    src="/img/icons/PLUS_BLACK.png"
                                    alt="minus"
                                    height={12}
                                  />
                                </span>
                                {di?.greenItems?.map((item, index) => {
                                  return (
                                    <div
                                      className="card-porduct pl-2"
                                      key={index}
                                    >
                                      <span className="d-block ">
                                        {item?.itemName}{" "}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    );
                })}
              </>
            )}
          </div>
          <div className=" d-block align-items-end footer">
            <OrderFooter
              order={order}
              // setCookingModalObj={setCookingModalObj}
              // setCookingModalOpen={setCookingModalOpen}
              dishProcessor={dishProcess}
              bar={true}
              cooked={cookingStatus}
              setDataLoading={setDataLoading}
              createdAt={order?.createdAt}
              cookingStarted={
                order?.cooking_started ? order?.cooking_started : false
              }
              handlePrintXML={handlePrintXML}
              handlePrint={handlePrint}
              activeStation={activeStation}
              setListenDataChange={setListenDataChange}
              selected_menu={selected_menu}
              sessions={sessions}
            />
          </div>
        </div>
        <div className="d-flex table-sec invisible">
          <div
            style={{ width: "300px", display: "hidden", height: "fit-content" }}
            ref={componentRef}
          >
            <div style={{ width: "100%", textAlign: "center" }}>
              <h2>
                {" "}
                {order?.sessionName} {order?.tableNumber}
              </h2>

              {order?.salesEntries?.map((item) => {
                if (
                  item?.station === selected_menu &&
                  (item?.status === "cooking" || item?.status === "pending")
                )
                  return (
                    <>
                      <h3>{item?.itemName}</h3>
                      <div className="dishName d-block px-2">
                        {item?.subLineItems.map((sl) => {
                          return (
                            <>
                              {(sl.type === "standard" ||
                                sl.type === "Standard" ||
                                sl.type === "normal") &&
                                sl.isSingleOnly === true ? (
                                <>
                                  <div className="d-flex ">
                                    <img
                                      src="/img/icons/MIDARROWRIGHT_BLACK.png"
                                      className="pt-1"
                                      height={20}
                                      alt="item-logo"
                                    />
                                    <span className="f-14 px-1">
                                      {sl?.itemName}
                                    </span>
                                  </div>
                                </>
                              ) : null}
                            </>
                          );
                        })}
                      </div>
                      {item?.redItems?.length > 0 ||
                        item?.greenItems?.length > 0 ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            gap: "2%",
                            textAlign: "start",
                          }}
                        >
                          <div
                            style={{
                              width: "49%",
                              border: "1px solid #465533",
                              padding: "15px",
                            }}
                          >
                            <h1 style={{ padding: "0", margin: "0" }}>-</h1>
                            {item?.redItems?.map((red_item, index) => {
                              return (
                                <h4
                                  style={{ padding: "0", margin: "0" }}
                                  key={index}
                                >
                                  {red_item?.itemName}
                                </h4>
                              );
                            })}
                          </div>
                          <div
                            style={{
                              width: "49%",
                              border: "1px solid #465533",
                              padding: "15px",
                            }}
                          >
                            <h1 style={{ padding: "0", margin: "0" }}>+</h1>
                            {item?.greenItems?.map((green_item, index) => {
                              return (
                                <h4
                                  style={{ padding: "0", margin: "0" }}
                                  key={index}
                                >
                                  {green_item?.itemName}
                                </h4>
                              );
                            })}
                          </div>
                        </div>
                      ) : null}
                      {item?.ingredients?.map((ing, index) => {
                        return (
                          <div className="ing-name px-2" key={index}>
                            <span className="d-block">
                              <span>{parseInt(ing?.quantity)} -</span>{" "}
                              {ing?.name}
                            </span>
                          </div>
                        );
                      })}
                    </>
                  );
              })}
            </div>
            <iframe
              ref={printref}
              style={{ height: "0px", width: "0px", position: "absolute" }}
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};
export default MultiOrders;
