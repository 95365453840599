/* eslint-disable prettier/prettier */
import React from "react";
import { useNavigate } from "react-router-dom";

const BottomNavGrayBtn = ({ text, logo, setHoverIcon, path, disabled }) => {
  const navigate = useNavigate();

  const redirectToPath = () => {
    if (!disabled) {
      navigate(path);
    }
  };
  return (
    <div
      className="btn-Gray"
      onMouseEnter={() => {
        setHoverIcon(text);
      }}
      onMouseLeave={() => {
        setHoverIcon(-1);
      }}
      onClick={() => redirectToPath()}
    >
      <div className="gray-icon py-2 d-flex justify-content-center align-items-center">
        <img
          src={logo}
          className="img-fluid"
          alt="assign"
          height={6}
          width={40}
        />
      </div>
      <h6>{text}</h6>
    </div>
  );
};

export default BottomNavGrayBtn;
