/* eslint-disable prettier/prettier */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MERGE_MODAL, REMOVE_MERGE_SELECTION } from "../../../../store/types";
import {
  deleteSession,
  payWaiter,
  SetSingleTableLoader,
} from "../../../../store/actions/tableSessionsAction";
import {
  // Dropdown,
  // DropdownItem,
  // DropdownMenu,
  // DropdownToggle,
  Row,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Spinner } from "reactstrap";
import axios from "axios";
import { IoMdNotifications, IoMdNotificationsOff } from "react-icons/io";

const KellnerCard = ({
  activeSession,
  serve,
  setSelectedSession,
  readyToBumpNServe,
  setAlertMsg,
  setWarn,
}) => {
  const dispatch = useDispatch();
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const toggle = () => setDropdownOpen((prevState) => !prevState);
  const { tableNo } = useSelector((state) => state?.modal);
  const [payWaiterLoading, setPayWaiterLoading] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const deleteSessionLoader = useSelector(
    (state) => state.tableSession.deleteSessionLoader
  );
  const orders = useSelector((state) => state.orders.orders);
  let order = orders.find((item) => item.sessionId === activeSession?._id);

  const theServeLoader = useSelector(
    (state) => state?.tableSession.theServeLoader
  );
  const payWaiterLoader = useSelector(
    (state) => state.tableSession.payWaiterLoader
  );
  const { singleLoaderTable } = useSelector((state) => state?.tableSession);
  useEffect(() => {
    if (!deleteSessionLoader) {
      setDeleteLoader(deleteSessionLoader);
    }
  }, [deleteSessionLoader]);
  useEffect(() => {
    if (!payWaiterLoader) {
      setPayWaiterLoading(payWaiterLoader);
    }
  }, [payWaiterLoader]);
  ///// =============== Merge ==================
  const setTableToMerge = () => {
    dispatch({
      type: MERGE_MODAL,
      payload: {
        firstTableName: {
          id: activeSession?._id,
          number: activeSession?.table_id,
          name: activeSession?.table_name,
          parentId: activeSession?.parentId
            ? activeSession?.parentId
            : activeSession?._id,
        },
      },
    });
  };

  //REMOVE SELECTION

  const removeSetSelection = () => {
    dispatch({
      type: REMOVE_MERGE_SELECTION,
    });
  };

  const removeSession = (id) => {
    setDeleteLoader(true);
    dispatch(deleteSession(id));
  };
  //notifyKitchen
  const notifyKitchen = (finalBill) => {
    let msgDetails = `${new Date()} | Pay to Waiter | Table Name: ${activeSession?.table_id
      } | Order Total: €${finalBill?.toFixed(2)}`; //Tip Amount: €${(total - subAmount).toFixed(2)
    // const msg = {
    //   problemID: uuidv4(),
    //   tableNo: cookOrder?.table_id,
    //   sessionID: cookOrder?._id,
    //   message: msgDetails.trim(),
    //   payWaiter: true,
    // };
    // socket.emit("Problem_", msg);
    msgDetails = msgDetails + `| Table No: ${activeSession?.table_id}`;
    return msgDetails;
  };
  // handle Payment
  const handleWaiterPayment = () => {
    if (order?.payment_status === "pending") {
      let finalBill = order?.salesEntries?.reduce(function(acc, obj) {
        return acc + Number(obj.itemPrice);
      }, 0);
      const waiterPayload = {
        businessLocationId: String(activeSession?.businessLocationId),
        thirdPartyReference: uuidv4(),
        orderType: "served",
        sessionID: activeSession?._id,
        tableNumber: Number(activeSession?.table_id),
        orderNote: notifyKitchen(finalBill),
        totalAmount: finalBill,
      };
      setPayWaiterLoading(true);
      dispatch(payWaiter(waiterPayload));
    } else {
      setAlertMsg("Oops..! This Order has been paid.");
      setWarn(true);
    }
  };

  const [isPaymentCanceled, setIsPaymentCanceled] = useState(false);

  const getCanceledPaymentInfo = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BackendURL +
        `/payment-info/session/${activeSession?.parentId}`,
        {}
      );
      if (response.data === "Canceled") return true;
    } catch (error) {
      console.error("Error fetching payment info:", error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const canceledPaymentInfo = await getCanceledPaymentInfo();
        setIsPaymentCanceled(canceledPaymentInfo);
      } catch (error) {
        console.error("Error fetching payment info:", error.message);
      }
    };

    fetchData();
  }, [activeSession?.parentId]);

  const notifStatus = useSelector((state) => state.tableSession.sessions);
  let notification = notifStatus.find((item) => item._id === activeSession?.parentId && item.notificationStatus !== undefined)
  let tableType = useMemo(() => notification?.table_type, [notification])

  return (
    <div className={serve ? "k-card-cooked mt-2" : "k-card mt-2"}>
      {singleLoaderTable?._id === activeSession?._id && theServeLoader ? (
        <Row className="p-5 mt-5 d-flex justify-content-center align-items-center">
          <Spinner />
        </Row>
      ) : (
        <>
          <div
            className={
              tableNo?.firstTableName?.id === activeSession?._id
                ? "item-overlay"
                : "abc"
            }
            onClick={() => {
              removeSetSelection();
            }}
          ></div>
          <div className="d-flex justify-content-between p-3 ">
            <div onClick={setSelectedSession}>
              <div className="">
                <b>{activeSession?.table_name}</b>
              </div>
              <div className="">
                TABLE <b>{activeSession?.table_id}</b>
              </div>
              <div style={{ textAlign: "right" }}>
                {
                  isPaymentCanceled &&
                  <img
                    src="/img/alert.gif"
                    alt="GIF"
                    style={{ width: "15px", height: "15px" }}
                  />
                }
              </div>
            </div>
            <img
              src="/img/icons/selection.png"
              height={20}
              onClick={() => {
                setTableToMerge();
              }}
            />
            {/* <div className="d-flex action-session">
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle>
                  <img src="/img/icons/dots.png" height={20} />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={setSelectedSession}>
                    View Order
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setTableToMerge();
                    }}
                  >
                    Select Session
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div> */}
          </div>
          <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', paddingBottom: '1rem' }}>
            {notification !== undefined && tableType == 'Pick-up' ? (
              <div>{notification.notificationStatus ? (
                <IoMdNotifications />
              ) : (
                <IoMdNotificationsOff color="red" />
              )

              }</div>

            ) : (
              <div style={{ width: "20px", height: "33px" }}></div>
            )

            }
          </div>
          <div className="k-info d-flex justify-content-center served pt-2 pb-3">
            {activeSession?.station && (
              <div className="bg-green w-100">
                <div className="text-center">
                  <b>{activeSession?.station}</b>
                </div>
              </div>
            )}
            <div className="w-100 bg-yellow ">
              <div className="text-center">
                {activeSession?.order_type.toUpperCase() || "CLOSED"}
              </div>
            </div>
          </div>

          <div className="py-2">
            <div className="btn-k-pay d-flex justify-content-center w-100">
              <div className="k-pay my-2 d-flex justify-content-center bg-darkBlue">
                <img
                  src="/img/icons/BUMP_WHITE.png"
                  onClick={() => {
                    dispatch(SetSingleTableLoader(activeSession));
                    readyToBumpNServe();
                  }}
                />
              </div>
            </div>
            <div className="btn-k-pay d-flex justify-content-between w-100">
              <button
                disabled={payWaiterLoading}
                onClick={handleWaiterPayment}
                className="k-pay bg-darkBlue my-2 d-flex align-items-center justify-content-center"
              >
                {payWaiterLoading ? <Spinner /> : "KELLNER PAY"}
              </button>

              <div
                className="k-pay bg-red my-2 ms-2 d-flex align-items-center text-center"
                onClick={() => {
                  if (!deleteLoader) {
                    removeSession(activeSession?._id);
                  }
                }}
              >
                {deleteLoader ? <Spinner /> : "  DELETE SESSION"}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default KellnerCard;
